<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white bd bdrs-3 p-20 mB-20">
        <h2 class="c-grey-900 mB-20" style="display: inline-block">
          {{chapter}}
        </h2>
        <span style="border-right: 1px solid #ddd; margin: 0 10px"></span>
        <button class="btn btn-sm btn-success" @click="addLesson">
          <i class="ti-plus mL-5" style="color: #fff"></i>إضافة درس
        </button>
        <div id="dataTable_wrapper" class="dataTables_wrapper">
          <form @submit="checkForm">
            <div class="form-group row pB-30 pT-30">
              <div class="col-sm-3">
                <input
                  type="number"
                  class="form-control"
                  v-model="lessonId"
                  placeholder="رقم الدرس"
                />
              </div>
              <div class="col-sm-3">
                <input
                  type="text"
                  class="form-control"
                  v-model="lessonName"
                  placeholder="إسم الدرس"
                />
              </div>
              <div class="col-sm-2">
                <button
                  type="submit"
                  class="btn btn-primary mL-10"
                  :disabled="loading"
                >
                  {{ $t("search") }}
                </button>
              </div>
            </div>
          </form>
          <table
            id="dataTable"
            class="table table-bordered dataTable table-hover"
            role="grid"
            aria-describedby="dataTable_info"
            style="width: 100%"
            width="100%"
            cellspacing="0"
          >
            <thead>
              <tr role="row">
                <th
                  class="sorting_asc"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-sort="ascending"
                  aria-label="Name: activate to sort column descending"
                >
                  #
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Position: activate to sort column ascending"
                >
                  {{ $t("name") }}
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  aria-label="Start date: activate to sort column ascending"
                >
                 عدد الأسئلة
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 30px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  تعديل
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 30px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  تفريغ
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 30px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  حذف
                </th>
                <th
                  class="sorting"
                  tabindex="0"
                  aria-controls="dataTable"
                  rowspan="1"
                  colspan="1"
                  style="width: 110px"
                  aria-label="Start date: activate to sort column ascending"
                >
                  عرض الأسئلة
                </th>
              </tr>
            </thead>
            <tbody v-show="!loading" v-for="item in lessons" :key="item.id">
              <tr role="row" class="odd">
                <td class="sorting_1">{{ item.id }}</td>
                <td>
                  {{item.name }}
                </td>
                <td v-if="item.questions_count > 0">
                  {{ item.questions_count}} سؤال 
                </td>
                <td v-else class="disabled">
                  لا يوجد أي سؤال 
                </td>
                <td>
                  <a href="javascript:"
                  @click="editLesson(item)"
                    ><span class="badge rounded-pill bgc-amber-50 c-amber-700"
                      ><i class="ti-pencil"></i></span
                  ></a>
                  </td>
                  <td>
                  <a v-if="emptyLoading && item.is_loading" href="javascript:"
                    ><loading style="color:green;"/></a>
                  <a  v-else href="javascript:"
                  @click="showPopup(item,false)"
                    ><span class="badge rounded-pill bgc-green-50 c-green-700"
                      ><i class="ti-brush-alt"></i></span
                  ></a>
                  
                </td>
                  <td>
                  <a v-if="deleteLoading && item.is_loading" href="javascript:"
                    ><loading style="color:red;"/></a>
                  <a  v-else href="javascript:"
                  @click="showPopup(item,true)"
                    ><span class="badge rounded-pill bgc-red-50 c-red-700"
                      ><i class="ti-trash"></i></span
                  ></a>
                  
                </td>
                  <td>
                  <a href="javascript:"
                  @click="showQuestion(item)"
                    ><span class="badge rounded-pill c-light-blue-500"
                      ><i class="ti-eye"></i></span
                  ></a>
                </td>
              </tr>
            </tbody>
          </table>
          <loading v-show="loading" />
          <p v-show="!lessons.length" style="text-align: center">
            {{ $t("noData") }}
          </p>
          <div style="text-align:center;margin-top:40px">
          <pagination style="display:inline-block;text-align:center" v-model="page" :records="pagination.total ?pagination.total : 2 " :per-page="10" @paginate="getLessons"/>
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div  class="modal-header" style="direction: rtl;">
      <h5 class="modal-title text-right" id="exampleModalLongTitle">كلمة السر</h5>
      <button @click="exit" type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; left: 15px; top: 15px;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
      <div class="modal-body">
        <p>هل أنت متأكد من أنك تريد حذف الدرس"{{ lesson==null ? '' : lesson.name }}" ؟ بالمواصلة في ذلك انت على وشك حذف الدرس وجميع الاسئلة داخل الدرس.</p>
        <input class="form-control"
                    v-model="pass"
                    placeholder="كلمة السر"
                  />
      </div>
      <div v-if="!deleteLoading" class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="exit">إلغاء</button>
        <button type="button" class="btn btn-danger" @click="deleteLesson(lesson)">حذف</button>
      </div>
      <div v-else class="modal-footer">
        <loading />
      </div>
      
    </div>
  </div>
</div>

<div class="modal fade" id="exampleModal1" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true" data-backdrop="static" >
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div  class="modal-header" style="direction: rtl;">
      <h5 class="modal-title text-right" id="exampleModalLongTitle">كلمة السر</h5>
      <button @click="exit" type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; left: 15px; top: 15px;">
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
      <div class="modal-body">
        <p>هل أنت متأكد من أنك تريد تفريغ الدرس"{{ lesson==null ? '' : lesson.name }}" ؟ بالمواصلة في ذلك انت على وشك تفريغ جميع الاسئلة داخل الدرس.</p>
        <input class="form-control"
                    v-model="pass"
                    placeholder="كلمة السر"
                  />
      </div>
      <div v-if="!deleteLoading" class="modal-footer">
        <button type="button" class="btn btn-secondary" data-dismiss="modal" @click="exit">إلغاء</button>
        <button type="button" class="btn btn-success" @click="emptyLesson(lesson)">تفريغ</button>
      </div>
      <div v-else class="modal-footer">
        <loading />
      </div>
      
    </div>
  </div>
</div>
  </div>
</template>

<script>
export default {
    props:{
    id: {
      type: String,
      required: true
    },
    chapter: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      loading: true,
      lessonName: "",
      deleteLoading: false,
      emptyLoading:false,
      lessonId: "",
      lessons: [],
      pagination: {},
      pass: '',
      lesson: null,
      page:1,
    };
  },
  created() {
    this.getLessons();
  },
  methods: {
    exit() {
        document.addEventListener('keydown', this.handleArrowKey);
        this.pass = "";
      },
    showPopup(item,isDelete) {
      this.lesson = item;
        document.removeEventListener('keydown', this.handleArrowKey);
        if(isDelete) {
          $('#exampleModal').modal('show');
        }else {
          $('#exampleModal1').modal('show');
        }
      },
      deleteLesson: function (lesson) {
        if(this.pass == '') {
        return;
      }
      if(this.deleteLoading || this.emptyLoading) {
        return;
      }
      lesson.is_loading = true;
      this.deleteLoading = true;
      let headers = {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      };
      this.$http
        .post("https://yalla-emtihan.com/backend/lessons/deleteLesson", 
        {
          lesson_id: lesson.id,
          pass:this.pass
        },
        { headers })
        .then(
          function (data) {
            lesson.is_loading = false;
            this.deleteLoading = false;
            $('#exampleModal').modal('hide');
            switch (data.status) {
              case 200:
                this.success(this.$t("success"));
                this.getLessons();
                break;
            }
          },
          (err) => {
            lesson.is_loading = false;
            this.deleteLoading = false;
            $('#exampleModal').modal('hide');
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          }
        );
    },
    emptyLesson: function (lesson) {
      if(this.pass == '') {
        return;
      }
      if(this.deleteLoading || this.emptyLoading) {
        return;
      }
      lesson.is_loading = true;
      this.emptyLoading = true;
      let headers = {
        Authorization: "Bearer " + sessionStorage.getItem("token"),
      };
      this.$http
        .post("https://yalla-emtihan.com/backend/lessons/emptyLesson", 
        {
          lesson_id: lesson.id,
          pass:this.pass
        },
        { headers })
        .then(
          function (data) {
            lesson.is_loading = false;
            this.emptyLoading = false;
            $('#exampleModal1').modal('hide');
            switch (data.status) {
              case 200:
                this.success(this.$t("success"));
                this.getLessons();
                break;
            }
          },
          (err) => {
            lesson.is_loading = false;
            this.emptyLoading = false;
            $('#exampleModal1').modal('hide');
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          }
        );
    },
    showQuestion(lesson) {
      this.$router.push("/questions?id="+lesson.id+"&lesson="+this.chapter+"-"+lesson.name);
    },
    checkForm: function (e) {
      e.preventDefault();
      this.getLessons();
    },
    getLessons: function () {
      this.loading = true;
      this.$http
        .get("https://yalla-emtihan.com/backend/lessons/getLessons", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            lesson_id: this.lessonId,
            lesson_name: this.lessonName,
            chapter_id: this.id,
            page: this.page
          },
        })
        .then(
          function (data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                this.lessons = data.body.data;
                this.pagination = data.body;
                console.log(this.pagination);
                break;
            }
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          }
        );
    },
    addLesson: function () {
      const panel1Handle = this.$showPanel({
        component: () => import("../../chapters/lessons/AddLessons.vue"),
        openOn: "right",
        width: 700,
        disableBgClick: true,
        props: {
          reload:this.getLessons,
          chapterId: this.id
        },
      });
    },
    editLesson(lesson) {
      const panel1Handle = this.$showPanel({
        component: () => import("../../chapters/lessons/EditLesson.vue"),
        openOn: "right",
        width: 700,
        disableBgClick: true,
        props: {
          lesson: lesson,
          reload:this.getLessons
        },
      });

      panel1Handle.promise.then((result) => {});
    },
    error: function (message) {
      this.$toast.error(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    warning: function (message) {
      this.$toast.warning(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
    success: function (message) {
      this.$toast.success(message, {
        position: "top-right",
        timeout: 3000,
        closeOnClick: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: true,
        rtl: false,
      });
    },
  },
};
</script>

<style>
</style>